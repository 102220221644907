import axios from "axios";
import store from "../store"

const API_URL = process.env.VUE_APP_AIS_BASE_URL;
const API_WORD = "/projekt";

axios.defaults.headers.common["Authorization"] = "Bearer " +  store.getters.getToken;

class ProjektDataService {
  getAll() {
    return axios.get(`${API_URL}${API_WORD}`);
  }
  delete(payload) {
    return axios.delete(`${API_URL}${API_WORD}`, { data: payload });
  }
  getById(id) {
    return axios.get(`${API_URL}${API_WORD}/${id}`);
  }
  update(payload) {
    return axios.put(`${API_URL}${API_WORD}`, payload);
  }
  create(payload) {
    return axios.post(`${API_URL}${API_WORD}`, payload);
  }
}

export default new ProjektDataService();
