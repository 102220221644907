import axios from "axios";
import store from "../store"

const API_URL = process.env.VUE_APP_AIS_BASE_URL;

axios.defaults.headers.common["Authorization"] = "Bearer " +  store.getters.getToken;

class HelperService {
  getAllPosibleEdgeTypes(type) {
    return axios.get(`${API_URL}/${type}/edges/possibleTypes`);
  }
  getAllPosibleConnections(type,edgeType)  {
    return axios.get(`${API_URL}/${type}/edges/possibleDestinationTypes/${edgeType}`);
  }
}

export default new HelperService();
