var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"modal-fade"}},[_c('div',{staticClass:"modal-backdrop"},[_c('div',{staticClass:"modal"},[_c('header',{staticClass:"modal-header",attrs:{"id":"modalTitle"}},[_vm._t("header",function(){return [_vm._v(" "+_vm._s(_vm.$t("entity.references.head", { vertexName: _vm.vertexName })))]}),_c('button',{staticClass:"btn-close",attrs:{"type":"button"},on:{"click":_vm.close}},[_vm._v("x")])],2),_c('section',{staticClass:"modal-body",attrs:{"id":"modalDescription"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.references,"dense":"","search":_vm.filter},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('common.search'),"single-line":"","hide-details":""},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t("common.button.add"))+" ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('p',{staticClass:"subtitle-2 text-left",domProps:{"innerHTML":_vm._s(_vm.formDescription)}}),_c('v-divider',{staticClass:"mx-1"}),_c('v-container',[_c('v-form',{ref:"form_data",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-select',{attrs:{"items":_vm.edgeTypes,"label":_vm.$t('entity.references.edgeType.short'),"item-text":"simpleName","item-value":"name","rules":_vm.rules.edgeType,"return-object":""},model:{value:(_vm.editedItemInner.edgeType),callback:function ($$v) {_vm.$set(_vm.editedItemInner, "edgeType", $$v)},expression:"editedItemInner.edgeType"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-select',{attrs:{"items":_vm.vertexTypes,"label":_vm.$t(
                                'entity.references.destinationVertexType.short'
                              ),"item-text":"vertexType","item-value":"simpleName","rules":_vm.rules.destinationVertexType,"selectable":_vm.destEnable},model:{value:(_vm.editedItemInner.destinationVertexType),callback:function ($$v) {_vm.$set(_vm.editedItemInner, "destinationVertexType", $$v)},expression:"editedItemInner.destinationVertexType"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.destVertices,"label":_vm.$t(
                                  'entity.references.destinationVertexName.short'
                                ),"item-text":"ident","item-value":"vertexName","rules":_vm.rules.destinationVertex,"selectable":_vm.destEnable},model:{value:(_vm.editedItemInner.destinationVertexName),callback:function ($$v) {_vm.$set(_vm.editedItemInner, "destinationVertexName", $$v)},expression:"editedItemInner.destinationVertexName"}})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.close2}},[_vm._v(" "+_vm._s(_vm.$t("common.button.cancel"))+" ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t("common.button.assume"))+" ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.$t("common.question.delete")))]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.closeDelete}},[_vm._v(_vm._s(_vm.$t("common.button.cancel")))]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v(_vm._s(_vm.$t("common.button.ok")))]),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
                                var item = ref.item;
return [_c('v-icon',{attrs:{"small":"","color":"error"},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}}],null,true),model:{value:(_vm.selectedRows),callback:function ($$v) {_vm.selectedRows=$$v},expression:"selectedRows"}})],1),_c('footer',{staticClass:"modal-footer"},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-snackbar',{attrs:{"timeout":_vm.snackbar_timeout,"color":"darklagoon"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
                                var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"amarillo","text":""},on:{"click":function($event){_vm.snackbarInner = false}}},'v-btn',attrs,false),[_vm._v(" "+_vm._s(_vm.$t("common.button.close"))+" ")])]}}]),model:{value:(_vm.snackbarInner),callback:function ($$v) {_vm.snackbarInner=$$v},expression:"snackbarInner"}},[_vm._v(" "+_vm._s(_vm.message)+" ")]),_c('v-spacer'),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t("common.button.close"))+" ")])],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }