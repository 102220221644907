<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div class="modal">
        <header class="modal-header" id="modalTitle">
          <slot name="header">
            {{ $t("entity.references.head", { vertexName: vertexName }) }}</slot>
          <button type="button" class="btn-close" @click="close">x</button>
        </header>

        <section class="modal-body" id="modalDescription">
          <v-data-table :headers="headers" :items="references" class="elevation-1" dense v-model="selectedRows"
            :search="filter">
            <template v-slot:top>
              <v-toolbar flat>
                <v-card-title>
                  <v-text-field v-model="filter" append-icon="mdi-magnify" :label="$t('common.search')" single-line
                    hide-details />
                </v-card-title>
                <v-spacer />
                <v-dialog v-model="dialog" max-width="500px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                      {{ $t("common.button.add") }}
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="text-h5">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text>
                      <p v-html="formDescription" class="subtitle-2 text-left" />
                      <v-divider class="mx-1" />
                      <v-container>
                        <v-form ref="form_data" v-model="valid">
                          <v-row>
                            <v-col cols="12" sm="6" md="6">
                              <v-select :items="edgeTypes" v-model="editedItemInner.edgeType"
                                :label="$t('entity.references.edgeType.short')" item-text="simpleName" item-value="name"
                                :rules="rules.edgeType" return-object />
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                              <v-select :items="vertexTypes" v-model="editedItemInner.destinationVertexType" :label="
                                $t(
                                  'entity.references.destinationVertexType.short'
                                )
                              " item-text="vertexType" item-value="simpleName" :rules="rules.destinationVertexType"
                                :selectable="destEnable" />
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                              <v-autocomplete :items="destVertices" v-model="editedItemInner.destinationVertexName"
                                :label="
                                  $t(
                                    'entity.references.destinationVertexName.short'
                                  )
                                " item-text="ident" item-value="vertexName" :rules="rules.destinationVertex"
                                :selectable="destEnable" />
                            </v-col>
                          </v-row>
                        </v-form>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer />
                      <v-btn color="primary" text @click="close2">
                        {{ $t("common.button.cancel") }}
                      </v-btn>
                      <v-btn color="primary" text @click="save">
                        {{ $t("common.button.assume") }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialogDelete" max-width="500px">
                  <v-card>
                    <v-card-title class="text-h5">{{
                    $t("common.question.delete")
                    }}</v-card-title>
                    <v-card-actions>
                      <v-spacer />
                      <v-btn color="primary" text @click="closeDelete">{{
                      $t("common.button.cancel")
                      }}</v-btn>
                      <v-btn color="primary" text @click="deleteItemConfirm">{{
                      $t("common.button.ok")
                      }}</v-btn>
                      <v-spacer />
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small @click="deleteItem(item)" color="error">
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
        </section>
        <footer class="modal-footer">
          <v-toolbar flat>
            <v-snackbar v-model="snackbarInner" :timeout="snackbar_timeout" color="darklagoon">
              {{ message }}
              <template v-slot:action="{ attrs }">
                <v-btn color="amarillo" text v-bind="attrs" @click="snackbarInner = false">
                  {{ $t("common.button.close") }}
                </v-btn>
              </template>
            </v-snackbar>
            <v-spacer />
            <v-btn color="primary" dark class="mb-2" @click="close">
              {{ $t("common.button.close") }}
            </v-btn>
          </v-toolbar>
        </footer>
      </div>
    </div>
  </transition>
</template>

<script>
import GenericService from "../../services/GenericService.js";
import HelperService from "../../services/HelperService";
export default {
  name: "Modal",
  props: ["editedItem", "vertextType", "linkType"],
  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? this.$t("entity.references.question.add")
        : this.$t("entity.references.question.change");
    },
    formDescription() {
      return this.$t("entity.references.question.form");
    }
  },
  watch: {
    editedItem: function () {
      this.initialize();
    },
    dialog(val) {
      val || this.close2();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    async "editedItemInner.edgeType"() {
      if (
        this.editedItemInner.edgeType != "" &&
        this.editedItemInner.edgeType != null
      ) {
        if (typeof this.editedItemInner.edgeType !== "undefined") {
          let type = this.originLinkType;
          let edgeType =  this.editedItemInner.edgeType.name;
          let resp = await HelperService.getAllPosibleConnections(type, edgeType);
          if (resp.status == 200) {
            this.vertexTypes = resp.data;
            this.destEnable = false;
          }
        }
      }
    },
    async "editedItemInner.destinationVertexType"() {
      if (
        this.editedItemInner.destinationVertexType != "" &&
        this.editedItemInner.destinationVertexType != null
      ) {
        if (typeof this.editedItemInner.destinationVertexType !== "undefined") {
          let type = this.editedItemInner.destinationVertexType;
          let resp = await GenericService.getAll(type);
          if (resp.status == 200) {
            this.destVertices = resp.data;
          }
        }
      }
    }
  },
  data() {
    return {
      valid: true,
      dialog: false,
      dialogDelete: false,
      vertexName: "",
      originVertexType: "",
      originLinkType: "",
      edgeTypes: [],
      vertexTypes: [],
      destVertices: [],
      destEnable: true,
      headers: [
        {
          text: this.$t("entity.references.edgeType.middel"),
          value: "edgeType",
          filterable: false
        },
        {
          text: this.$t("entity.references.originVertexName.middel"),
          value: "originVertexName",
          filterable: true
        },
        {
          text: this.$t("entity.references.originVertexType.middel"),
          value: "originVertexType",
          filterable: true
        },
        {
          text: this.$t("entity.references.destinationVertexName.middel"),
          value: "destinationVertexName",
          filterable: true
        },
        {
          text: this.$t("entity.references.destinationVertexType.middel"),
          value: "destinationVertexType",
          filterable: true
        },
        {
          text: this.$t("common.field.actions.middel"),
          value: "actions",
          sortable: false,
          filterable: false
        }
      ],
      rules: {
        edgeType: [v => !!v || this.$t("entity.references.edgeType.rule")],
        destinationVertexType: [
          v => !!v || this.$t("entity.references.destinationVertexType.rule")
        ],
        destinationVertex: [
          v => !!v || this.$t("entity.references.destinationVertexName.rule")
        ]
      },
      references: [],
      editedIndex: -1,
      editedItemInner: {
        edgeType: "",
        originVertexName: "",
        originVertexTyp: "",
        destinationVertexName: "",
        destinationVertexType: "",
        vertexName: ""
      },
      deletedItem: {
        edgeType: "",
        originVertexName: "",
        originVertexTyp: "",
        destinationVertexName: "",
        destinationVertexType: "",
        vertexName: ""
      },
      defaultItem: {
        edgeType: "",
        originVertexName: "",
        originVertexTyp: "",
        destinationVertexName: "",
        destinationVertexType: "",
        vertexName: ""
      },
      isEditModus: false,
      filter: "",
      selectedRows: [],
      message: "",
      snackbarInner: false,
      snackbar_timeout: 8000
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    save() {
      this.validate();
      if (this.valid) {
        if (this.editedIndex > -1) {
          this.updateService();
        } else {
          this.addService();
        }
        this.close2();
      }
    },
    initialize() {
      this.vertexName = this.editedItem.vertexName;
      this.editedItemInner.originVertexName = this.editedItem.vertexName;
      this.references = this.editedItem.references;
      this.originVertexType = this.vertextType;
      this.originLinkType = this.linkType;
      this.loadEdgeTypes();
    },
    async loadEdges(ismessage) {
      let type = this.originLinkType;
      let vertexName = this.editedItemInner.originVertexName;
      try {
        let resp = await GenericService.getAllEdges(type, vertexName);
        if (resp.status == 200 || resp.status == 201) {
          this.references = resp.data;
          this.editedItem.references = this.references;
          if (ismessage == true) {
            this.message = this.$t("common.messages.load");
            this.snackbarInner = true;
          }
        } else {
          this.message = resp.data;
          this.snackbarInner = true;
        }
      } catch (err) {
        this.message = err;
        this.snackbarInner = true;
      }
    },
    async loadEdgeTypes() {
      let type = this.originLinkType;
      let resp = await HelperService.getAllPosibleEdgeTypes(type);
      if (resp.status == 200) {
        this.edgeTypes = resp.data;
      } else {
        this.message = resp.data;
        this.snackbarInner = true;
      }
    },
    deleteItem(item) {
      this.editedIndex = this.references.indexOf(item);
      this.deletedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.references.splice(this.editedIndex, 1);
      this.deleteService();
      this.closeDelete();
    },
    close2() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItemInner = Object.assign({}, this.defaultItem);
        this.editedItemInner.originVertexName = this.editedItem.vertexName;
        this.editedIndex = -1;
        this.isEditModus = false;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItemInner = Object.assign({}, this.defaultItem);
        this.deletedItemInner = Object.assign({}, this.defaultItem);
        this.editedItemInner.originVertexName = this.editedItem.vertexName;
        this.editedIndex = -1;
      });
    },
    async addService() {
      try {
        let type = this.originLinkType;
        let dataobject = {
          edgeType: this.editedItemInner.edgeType.name,
          originVertexName: this.editedItemInner.originVertexName,
          originVertexType: this.originVertexType,
          destinationVertexName: this.editedItemInner.destinationVertexName,
          destinationVertexType: this.editedItemInner.destinationVertexType.toUpperCase()
        };
        let resp = await GenericService.createEdge(type, dataobject);
        if (resp.status == 201) {
          this.message = this.$t("common.messages.save");
          this.snackbarInner = true;
          this.loadEdges(false);
        } else {
          this.message = resp.data;
          this.snackbarInner = true;
        }
      } catch (err) {
        this.message = err;
        this.snackbarInner = true;
        this.loadEdges(false);
      }
    },
    async deleteService() {
      try {
        let type = this.originLinkType;
        let dataobject = this.deletedItem;
        let resp = await GenericService.deleteEdge(type, dataobject);
        if (resp.status == 204) {
          this.message = this.$t("common.messages.save");
          this.loadEdges(false);
          this.snackbarInner = true;
        } else {
          this.message = resp.errorMessage;
          this.snackbarInner = true;
        }
      } catch (err) {
        this.message = err;
        this.snackbarInner = true;
        this.loadEdges(false);
      }
    },
    validate() {
      this.$refs.form_data.validate();
    }
  }
};
</script>

<style>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background: #ffffff;
  box-shadow: 2px 2px 20px 1px;
  min-width: 50%;
  min-height: 60%;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
}

.modal-header,
.modal-footer {
  padding: 15px;
  display: flex;
}

.modal-header {
  position: relative;
  border-bottom: 1px solid #eeeeee;
  color: #003a40;
  justify-content: space-between;
}

.modal-footer {
  border-top: 1px solid #eeeeee;
  flex-direction: column;
}

.modal-body {
  position: relative;
  padding: 20px 10px;
  flex: 1;
}

.btn-close {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  font-size: 20px;
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
  color: #003a40;
  background: transparent;
}

.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}
</style>