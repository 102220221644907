import axios from "axios";
import store from "../store"

const API_URL = process.env.VUE_APP_AIS_BASE_URL;

axios.defaults.headers.common["Authorization"] = "Bearer " +  store.getters.getToken;

class GenericServcie {
  getAll(type) {
    return axios.get(`${API_URL}/${type}`);
  }
  getAllEdges(type,vertexName) {
    let url = `${API_URL}/${type}/edges/${vertexName}`;
    return axios.get(url);
  }
  createEdge(type,payload){
    let url = `${API_URL}/${type}/edges`;
    return axios.put(url, payload);
  }
  deleteEdge(type,payload){
    let url = `${API_URL}/${type}/edges`;
    return axios.delete(url, { data: payload });
  }

}

export default new GenericServcie();
