<template>
  <v-footer padless absolute class="blue-grey lighten-5">
    <v-container fluid>
      <v-row dense>
        <v-col cols="12" sm="4" class="grey--text text-left">
          <v-spacer/>
          <span>{{ $t("common.footer.appname") }}</span>
        </v-col>
        <v-col cols="12" sm="4">
          <v-spacer />
        </v-col>
        <v-col cols="12" sm="4" class="grey--text text-right">
          <span>{{ $t("common.footer.user", { user: username })  }}</span>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" class="grey--text text--lighten-1  text-center">
          &copy; {{ new Date().getFullYear() }} —
          <strong
            > {{ $t("common.footer.owner") }}</strong
          >
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template> 
<script>
export default {
  name: "Footer",
  data() {
    return {
      username: this.$store.getters.getUserName,
    };
  },
};
</script>
